import React from 'react';
import './index.css';
import Layout from './components/Layout';

const App: React.FC = () => {
  return (
    <Layout>
      <input
        type="text"
        className="border p-2 mb-2 w-full"
        placeholder="http://"
      />
      <button className="bg-blue-500 mt-2 text-white p-2 float-right">Lookup</button>
    </Layout>
  );
}

export default App;
